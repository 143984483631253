import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as CarBlack } from "../../../assets/images/car-black.svg";
import { useCart } from '../../../hooks/useCart';
import NavigationLink from '../../common/NavigationLink/NavigationLink';
import './Navbar.scss';

const Navbar = ({ inverted, sendToBack, openCart, setOpenCart }) => {
  const { pathname } = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [classNav, setClassNav] = useState("");
  const { cart } = useCart();
  const cartSize = Object.keys(cart).length;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

  const handleMenu = () => {
    if (menuOpen) {
      setMenuOpen(false);
      setClassNav("menu-closed");
    } else {
      setMenuOpen(true);
      setClassNav("menu-active");
    }
  };

  useEffect(() => {
    setClassNav('menu-closed')
  }, [pathname])

  return (
    <div className="navbar-container">
      <nav className={`Navbar ${(pathname === '/para-empresas') || pathname === '/contato' || pathname === '/solucoes' || (pathname.includes("/para-pessoas/cursos")) || (pathname.includes("/para-empresas/cursos")) ? "inverted" : ""} ${sendToBack ? "back" : ""} ${classNav}`}>
        <a href={window.location.origin}>
          <h1>mastertech</h1>
        </a>
        <button>
          <div onClick={() => setOpenCart(!openCart)} className='cart'>
            <button>
              <p>{cartSize}</p>
              <CarBlack />
            </button>
          </div>
          <div aria-label={menuOpen ? "fechar menu" : "abrir menu"} onClick={handleMenu}>
            <span />
            <span />
            <span />
          </div>
        </button>
        <div className={pathname.split("/", 2)[1] === 'compra' && "hidden" || pathname === '/cart' && "hidden"}>
          <NavigationLink href='/' text='Home' active={pathname === '/'}>Home</NavigationLink>
          <div
            className="navigation"
            onMouseEnter={() => setIsMenuOpen(true)}
            onMouseLeave={() => {
              setIsMenuOpen(false);
              setIsSubmenuOpen(false);
            }}
          >
            <NavigationLink
              href="/para-empresas"
              className="menu-link"
              highlight
              isOpen={isMenuOpen}
            >
              PARA EMPRESAS +
            </NavigationLink>

            <div className={`dropdown-menu ${isMenuOpen ? 'visible' : ''}`}>
              <a href="/trilhas-formativas">TRILHAS FORMATIVAS +</a>

              <div
                className="menu-item"
                onMouseEnter={() => setIsSubmenuOpen(true)}
                onMouseLeave={() => setIsSubmenuOpen(false)}
              >
                <a href="/team-building" className={`submenu-link ${isSubmenuOpen ? 'active' : ''}`}
                >
                  TEAM BUILDING +</a>

                <div className={`submenu ${isSubmenuOpen ? 'visible' : ''}`}>
                  <a href="/cooking-scrum">COOKING SCRUM</a>
                  <a href="/walking-tour">WALKING TOUR</a>
                  <a href="/banque-se">BANQUE-SE</a>
                </div>
              </div>

              <a href="/consultoria">CONSULTORIA</a>
              <a href="/projetos-educacionais">PROJETOS EDUCACIONAIS</a>
              <a href="/mtalks">M/TALKS</a>
            </div>
          </div>

          <NavigationLink href='/solucoes' active={pathname.includes('/solucoes')}>Soluções</NavigationLink>
          <div className='cart'>
            <button onClick={() => setOpenCart(!openCart)}>
              <p>{cartSize}</p>
              <CarBlack />
            </button>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Navbar;
