import React from 'react';
import { useNavigate } from 'react-router-dom';
import ticket from '../../assets/images/consultancy/section-ticket.png';
import { ReactComponent as Arrow } from "../../assets/images/mtalks/arrow-red-right.svg";
import ImageWithBadge from '../../components/ui/ImageWithBadge/ImageWithBadge';
import './Consultancy.scss';

const Consultancy = () => {
  const navigate = useNavigate();

  const navigateCase = (id) => {
    window.scrollTo(0, 0)
    navigate(`/cases/completed/${id}`)
  }

  return (
    <div className='Consultancy'>
      <div className="container-consultancy">
        <div className="max-section-contact">
          <div className="section-contact">
            <div className="texts-contact">
              <p className="text-contact first"><span>_____</span> DESENVOLVA HABILIDADES DIGITAIS</p>
              <p className="text-contact">E ENGAJE SUA COMUNIDADE PARA CRIAR</p>
              <p className="text-contact">PRODUTOS E SERVIÇOS DISRUPTIVOS COM</p>
              <p className="text-contact">A NOSSA METODOLOGIA ÁGIL,</p>
              <p className="text-contact">FOCADA EM PESSOAS.</p>
            </div>
            <button className="contact-button">
              ENTRE EM CONTATO <Arrow />
            </button>
          </div>
        </div>

        <div className="max-info-school">
          <div className="infos-school">
            <p className="description-school">Somos uma escola que ensina tecnologia e também somos uma consultoria de inovação que trabalha com o mercado corporativo para construir soluções para os nossos tempos. Oferecemos uma abordagem única que conjuga pensamento lógico, digital, ágil e humano para transformar a maneira como empresas operam e se relacionam com seus clientes.</p>

            <div className="list-box-school">
              <div className="box">
                <p>METODOLOGIA PRÓPRIA, <br /> BASEADA EM <span>9 PASSOS</span> <br /> DE TRANSFORMAÇÃO DIGITAL</p>
              </div>
              <div className="box">
                <p>EXPERIÊNCIA EM <span>PROJETOS B2B,</span> <br /> SOCIAIS E GOVERNAMENTAIS</p>
              </div>
              <div className="box">
                <p>ATUAÇÃO EM EMPRESAS <br /> COMO <span>FACEBOOK, BAYER, <br /> IBM E HEINEKEN</span></p>
              </div>
            </div>

            <p className="description-school">Nossa metodologia segue um roadmap de 9 passos que envolve a <strong>CONSCIENTIZAÇÃO</strong>, o <strong>CONHECIMENTO</strong> e a <strong>CONSOLIDAÇÃO</strong> de uma cultura digital e ágil. Combinamos técnicas de Lean Business, UX, Design Thinking e desenvolvimento de mindset ágil para fomentar uma mudança efetiva e duradoura.</p>
          </div>
        </div>

        <div className="max-pillars-section">
          <div className="pillars-section">
            <div className="pillars-school">
              <div className="pillar">
                <p>CONSCIENTIZAÇÃO</p>
                <p>Diagnóstico inicial <br /> e avaliação<br />  de cultura digital</p>
              </div>
              <div className="pillar">
                <p>CONHECIMENTO</p>
                <p>Laboratório ágil para desenvolver<br /> as habilidades necessárias</p>
              </div>
              <div className="pillar">
                <p>CONSOLIDAÇÃO</p>
                <p>Inception e Design Sprint<br /> para criar estratégias práticas</p>
              </div>
              <img src={ticket} alt="Ticket TECH" />
            </div>
          </div>
        </div>
        <div className="max-infos-pillars">
          <div className="info-pillars-section">
            <div className="text-info">
              <p>Apoiamos organizações que desenvolvem projetos junto a comunidades vulneráveis com metodologias que garantem empatia, colaboração e impacto social. Trabalhamos com setores públicos e privados para produzir soluções de engajamento digital.</p>
            </div>
            <div className="text-info">
              <p>Da ideação à prototipação, nossa equipe é especializada em converter ideias em produtos digitais funcionais e inovadores, utilizando técnicas de Design Sprint e frameworks ágeis para acelerar esse processo.</p>
            </div>
          </div>
        </div>

        <div className="list-cases">
          <h4>Cases de transformação digital</h4>
          <div className="box-cases">
            <div className="left-cases">
              <ImageWithBadge
                src="https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/case2.png"
                alt="New Image"
                badgeText="CASE"
                titleText="GLOBO | Desafio LED - Me dá uma luz aí."
                captionText="A educação no centro do palco e dos holofotes"
                size='100%'
                width='100%'
                height='60vh'
                onClick={() => navigateCase('led')}
              />
              <ImageWithBadge
                src="https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/case5.png"
                alt="New Image"
                badgeText="CASE"
                titleText="BAYER |"
                small
                captionText="Inception, uma co-criação inovadora"
                size='100%'
                width='100%'
                height='30vh'
                onClick={() => navigateCase('bayer')}
              />
            </div>

            <div className="right-cases">
              <ImageWithBadge
                src="https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/case1.png"
                alt="New Image"
                badgeText="CASE"
                titleText="HEINEKEN | Data drive mindset"
                captionText="Transformação digital e data driven mindset"
                size='100%'
                width='100%'
                height='30vh'
                onClick={() => navigateCase('heineken')}
              />
              <ImageWithBadge
                src="https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/cases-completo/alvarez-home.jpg"
                alt="New Image"
                badgeText="CASE"
                titleText="A&M |"
                small
                captionText="Os belts de conhecimento"
                size='100%'
                width='100%'
                height='60vh'
                onClick={() => navigateCase('alvarez')}
              />
            </div>
          </div>
        </div>

        <div className="max-section-footer">
          <div className="section-contact-footer">
            <div className="texts-contact">
              <p className="text-contact first">QUER TRANSFORMAR SUA EMPRESA</p>
              <p className="text-contact">OU COMUNIDADE? FALE COM NOSSOS</p>
              <p className="text-contact">ESPECIALISTAS E SAIBA COMO A MASTERTECH</p>
              <p className="text-contact">PODE TE AJUDAR</p>
            </div>
            <button className="contact-button">
              ENTRE EM CONTATO <Arrow />
            </button>
          </div>
        </div>
      </div>
    </div >
  )
}

export default Consultancy
