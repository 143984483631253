import React from 'react';
import sectionGenAi from "../../assets/images/genai/first-section-genai.png";
import logoGenAi from "../../assets/images/genai/logo-genai.png";
import { ReactComponent as Arrow } from "../../assets/images/mtalks/arrow-red-right.svg";
import './GenAi.scss';

const GenAi = () => {
  return (
    <div className='GenAi'>
      <div className="container-genai">
        <div className="header-genai">
          <div className="left-header">
            <h1>gen</h1>
            <h2>AI</h2>
          </div>
          <img src={logoGenAi} alt="Logo Gen AI" />
        </div>
        <div className="content-info-genai">
          <img src={sectionGenAi} alt="Image section Gen AI" />
          <p className="description">
            Destinado tanto a times de trabalho quanto às lideranças, nosso programa se propõe a desvendar as principais aplicações cotidianas do ChatGPT, como elaborar relatórios, traduzir textos e adaptá-los a diferentes contextos e finalidades, gerar insights, analisar dados e desenvolver projetos, além de pavimentar interações futuras com ferramentas correlatas.
          </p>
        </div>
        <div className="learning-section">
          <h2 className="learning-section__title">O QUE VOCÊ VAI APRENDER</h2>
          <div className="learning-section__content">
            <div className="learning-section__item learning-section__item--left">
              <div className="learning-section__item-learning-box">
                <h3 className="learning-section__item-title">
                  Fundamentos <br /> <span>do ChatGPT</span>
                </h3>
                <p className="learning-section__item-description">
                  Onde expomos a lógica do seu funcionamento, operações rotineiras, a construção de prompts eficazes e o gerenciamento dos resultados que a plataforma fornece.
                </p>
              </div>
              <div className="dot" />
            </div>
            <div className="learning-section__item learning-section__item--right">
              <div className="learning-section__item-learning-box">
                <h3 className="learning-section__item-title">
                  Uso avançado <br /> <span>do ChatGPT</span>
                </h3>
                <p className="learning-section__item-description">
                  Módulo em que nos aprofundamos no refinamento dos outputs, bem como na estruturação de documentos e planos de ação, e em códigos de programação para a automação de tarefas e análise dados.
                </p>
              </div>
              <div className="dot" />
            </div>
            <div className="learning-section__item learning-section__item--left">
              <div className="learning-section__item-learning-box">
                <h3 className="learning-section__item-title">
                  Exploração <br /> <span>de possibilidades</span>
                </h3>
                <p className="learning-section__item-description">
                  Trecho final da nossa formação, quando estabelecemos comparações entre o ChatGPT e as principais alternativas disponíveis, e testamos apps embarcados como Copilot e Gemini.
                </p>
              </div>
              <div className="dot" />
            </div>
            <p className="text-flutuant">
              gen IA
            </p>
          </div>
        </div>
        <div className="section-image">
          <div className="card-duration">
            <p className="card-duration-title">Duração</p>
            <p className="card-duration-content">3 encontros que totalizam 8 horas de aula ou uma versão enxuta, sem a seção dedicada à análise de dados, que pode ser percorrida em 6 horas.</p>
          </div>
          <div className="card-duration">
            <p className="card-duration-title">EXPERIÊNCIA</p>
            <p className="card-duration-content">Originalmente, nosso programa gen IA é híbrido, mas o seu formato também pode ser customizado de acordo com o desejo e as possibilidades do contratante.</p>
          </div>
          <div className="card-duration">
            <p className="card-duration-title">PARTICIPANTES</p>
            <p className="card-duration-content">Limite máximo de 40 pessoas por turma para que possamos garantir aulas de qualidade, efetivamente personalizadas.</p>
          </div>
        </div>

        <div className="section-contact">
          <div className="texts-contact">
            <p className="text-contact first">DESCUBRA COMO A <span>IA generativa</span></p>
            <p className="text-contact">PODE AUMENTAR A PRODUTIVIDADE</p>
            <p className="text-contact">DA SUA EMPRESA</p>
          </div>
          <button className="contact-button">
            ENTRE EM CONTATO <Arrow />
          </button>
        </div>
      </div>
    </div>
  )
}

export default GenAi
