export const talksData = [
  {
    number: "01",
    title: "Diversidade e inclusão na prática",
    description: "Desvendamos táticas e pavimentamos caminhos para implementar esses princípios na cultura organizacional e obter resultados tangíveis.",
    SassColor: "#F73B56"
  },
  {
    empty: true
  },
  {
    description: "A Mastertech conta com um catálogo de talks que vão dos conteúdos explorados em nossos cursos até questões comportamentais emergentes no cenário corporativo, incluindo discussões sobre tendências tecnológicas e a sensibilização para tópicos de relevo, como as pautas EGS e a exclusão digital. <br /><br /> Todas as nossas palestras mesclam rigor científico, consciência contextual e propósitos práticos, e são cuidadosamente customizadas para gerar engajamento, abrindo janelas para o aprendizado de novos conhecimentos por variados públicos.<br /><br /> Somando-se ao nosso raio de alcance temático e metodologia verificada, também contamos com a expertise de Camila Achutti, fundadora e CEO Mastertech, que além de empreendedora, é idealizadora da ONG SOMAS, conselheira de empresas e sociedades anônimas, delegada do W20, sub-grupo do G20, doutoranda em Ciências da Computação pela USP, precursora das influencers digitais com o blog “Mulheres na Computação”, vencedora dos prêmios Women of Vision (2015) e Forbes Under 30 (2017), personagem do Maurício de Sousa e dos documentários “Inovar é um parto” e “Cibernéticas”, narradora do podcast “Histórias de Ninar Garotas Rebeldes” e autora do livro “Razão nada poética”.<br /><br /> Vê-se, portanto, que temos propriedade não só para criar exposições personalizadas, como para transitar por searas como:",
    textSection: true
  },
  {
    empty: true
  },
  {
    number: "02",
    title: "A neuroplasticidade do <br/> “aprender a aprender”",
    description: "Ensinamos como utilizar a maleabilidade do cérebro humano para melhorar processos de aprendizado e o desenvolvimento de habilidades.",
    SassColor: "#1C1C1C"
  },
  {
    number: "03",
    title: "A neuroplasticidade do <br/> \"aprender a aprender\"",
    description: "O avanço da IAGen traz incertezas, mas também traz oportunidades. Estagnar ou prosperar depende do quanto nos abrimos às possibilidades que essa tecnologia oferece.",
    SassColor: "#1C1C1C"
  },
  {
    empty: true
  },
  {
    number: "04",
    title: "O uso do ChatGPT no desenvolvimento profissional",
    description: "A IAGen, que já está reconfigurando mercados e carreiras, também pode ser incorporada nos treinamentos corporativos para enriquecer o processo de aprendizado. Nós ensinamos como.",
    SassColor: "#1C1C1C"
  },
  {
    empty: true
  },
  {
    empty: true
  },
  {
    number: "05",
    title: "A mentalidade adaptativa <br/> dos humanos digitais",
    description: "Aprenda a abraçar a transformação digital e a cultivar um mindset voltado para a aprendizagem contínua.",
    SassColor: "#1C1C1C"
  },
  {
    empty: true
  },
  {
    number: "06",
    title: "A sociedade hiperconectada <br/> vista e analisada sobre o viés <br/> da cultura de dados",
    description: "Focamos no ser humano para transformar a maneira como os dados são vistos e utilizados na lógica corporativa.",
    SassColor: "#F73B56"
  },
  {
    number: "07",
    title: "O \"resgate do eu\" e o protagonismo<br/> não violento em ambientes<br/> colaborativos, diversos e inclusivos",
    description: "No contexto corporativo, onde a colaboração é vital, como podemos afirmar a nossa individualidade? Um mergulho no protagonismo não violento para fortalecer vozes enquanto se cultiva a harmonia.",
    SassColor: "#1C1C1C"
  },
  {
    empty: true
  },
  {
    number: "08",
    title: "Mulheres antifrágeis: sobre<br/> construção de carreira<br/> e lifelong learning",
    description: "Para mitigar desafios de mulheres programadoras, estudamos suas jornadas e promovemos uma mentalidade antifrágil no setor.",
    SassColor: "#1C1C1C"
  },
  {
    empty: true
  },
  {
    empty: true
  },
  {
    number: "09",
    title: "Mirrortocracy  - Eliminando vieses<br/> na mobilidade profissional",
    description: "Exploramos como os vieses cognitivos limitam soluções, afetam decisões e restringem a mobilidade, e propomos estratégias para criar um espaço de trabalho mais inclusivo e equitativo.",
    SassColor: "#F73B56"
  },
  {
    empty: true
  },
  {
    number: "10",
    title: "Você desenvolve códigos como<br/> se desenvolve?",
    description: "Em um mundo moldado por softwares, vemos paralelos entre o desenvolvimento de código e o desenvolvimento profissional, trazendo insights valiosos para as empresas.",
    SassColor: "#1C1C1C"
  }
];