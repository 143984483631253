import React from 'react';
import { ReactComponent as Arrow } from "../../assets/images/mtalks/arrow-red-right.svg";
import logoTalks from '../../assets/images/mtalks/logo-mtalks.png';
import './MTalks.scss';
import { talksData } from './talksData';

const MTalks = () => {

  return (
    <div className='MTalks'>
      <div className="container-talks">
        <div className="header-mtalks">
          <h2>m/</h2>
          <div className="image-header">
            <img src={logoTalks} alt="Camila Achutti" />
            <p>PALESTRAS COM CAMILA ACHUTTI</p>
          </div>
          <h1>talks</h1>
        </div>
        <div className="talks-section">
          {talksData?.map((talk, index) =>
            <div style={{ borderColor: talk.SassColor }} key={index} className={`talksbox talks${talk.number} ${talk.textSection && 'text-section'} ${talk.empty && 'empty'}`}>
              {talk.number && <p className='talk-number'>talks # {talk.number}</p>}
              {talk.title && <p className='talk-title' dangerouslySetInnerHTML={{ __html: talk.title }} />}
              {talk.textSection && <p dangerouslySetInnerHTML={{ __html: talk.description }} />}
              {!talk.textSection && <p className='talk-description' dangerouslySetInnerHTML={{ __html: talk.description }} />}
            </div>
          )}
        </div>
        <div className="section-image">
          <div className="card-duration">
            <p className="card-duration-title">Duração</p>
            <p className="card-duration-content">Nossas exposições são modeladas de acordo com o tema escolhido, a intenção de explorá-lo e, é claro, a agenda das pessoas participantes.</p>
          </div>
          <div className="card-duration">
            <p className="card-duration-title">EXPERIÊNCIA</p>
            <p className="card-duration-content">Preferimos o formato presencial, mas mais importante do que a nossa predileção é a oportunidade, seja onde for, de disseminar saberes e promover debates pertinentes aos dias que correm.</p>
          </div>
          <div className="card-duration">
            <p className="card-duration-title">PARTICIPANTES</p>
            <p className="card-duration-content">As palestras podem ser apresentadas para qualquer número de participantes.</p>
          </div>
        </div>
        <div className="section-contact">
          <div className="texts-contact">
            <p className="text-contact first">QUER OUVIR O QUE TEMOS PARA</p>
            <p className="text-contact">FALAR? É SÓ ENTRAR EM CONTATO PARA</p>
            <p className="text-contact">A GENTE MARCAR UM PAPO!</p>
          </div>
          <button className="contact-button">
            ENTRE EM CONTATO <Arrow />
          </button>
        </div>
      </div>
    </div>
  )
}

export default MTalks
